export function useUseHomePopup() {
  const showCityPopup = useState('showCityPopup', () => false)
  const showDatePopup = useState('showDatePopup', () => false)

  const filterStore = useFilterConditionsStore()

  const { query } = useRoute()
  const { listRoutePath } = usePartnerConfig()

  function handleHomeSearch() {
    if (!filterStore.filterConditions.selectedCity?.destinationId) {
      showCityPopup.value = true
      return
    }
    if (!filterStore.filterConditions.date) {
      showDatePopup.value = true
      return
    }

    navigateTo({
      path: listRoutePath.value,
      query,
    })
  }

  return {
    showCityPopup,
    showDatePopup,
    handleHomeSearch,
  }
}
